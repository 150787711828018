
body {
  background-color: #f6f6f6 !important;


}

.shake {
  animation: shake-animation 4.72s ease infinite;
  transform-origin: 50% 50%;
}

@keyframes shake-animation {
0% { transform:rotate(0deg) }
 2% { transform:rotate(12deg) }
 4% { transform:rotate(0deg) }
 6% { transform:rotate(-10deg) }
 8% { transform:rotate(0deg) }
 10% { transform:rotate(8deg) }
 12% { transform:rotate(0deg) }
 14% { transform:rotate(-6deg) }
 16% { transform:rotate(0deg) }
 100% { transform:rotate(0deg) }
}

// .model-viewer {
//   .progress-bar {
//     transition: 0.5s all;
//   }
// }